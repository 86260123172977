<template>
    <div class="contain">
        <div class="banner flex_cen">
            <div class="search-util">
                <div class="center txt fs_36">想直录，上职路</div>
                <div class="search-bar flex">
                    <div class="flex_c">
                        <el-input v-model.trim="keywords" name="keywords" placeholder="请输入内容" class="fs_16 flex_1" @keyup="keyupSearch"></el-input>
                        <el-button type="primary" class="fs_16" @click="search">搜人才</el-button>
                    </div>
                    <el-button class="fs_16 post-btn" @click="authPath(['/position/post'], linkTo)">发布岗位</el-button>
                </div>
                <div class="fs_14 txt flex_c">
                    <span class="mr_10">热门搜索：</span>
                    <span class="btn mr_15" v-for="(item, index) in hotList" :key="index" @click="handleValue(item)">{{ item }}</span>
                </div>
            </div>
        </div>

        <div class="pending-bar flex bgf">
            <div class="flex left">
                <div @click="authPath(['/dimission'], linkTo)" class="center item btn">
                    <img src="@/assets/imgs/index/nav1.png" class="icon" alt="" />
                    <p class="fs_14 info_color">离职人员待处理</p>
                    <div class="mt_15 color_333">
                        <span class="fs_26">{{ pendingData.check_num || 0 }}</span>
                        <span class="fs_14">人</span>
                    </div>
                </div>
                <div @click="authPath(['/position'], linkTo)" class="center item btn">
                    <img src="@/assets/imgs/index/nav2.png" class="icon" alt="" />
                    <p class="fs_14 info_color">简历投递待处理</p>
                    <div class="mt_15 color_333">
                        <span class="fs_26">{{ pendingData.resume_num || 0 }}</span>
                        <span class="fs_14">人</span>
                    </div>
                </div>
                <div @click="authPath(['/employment'], linkTo)" class="center item btn">
                    <img src="@/assets/imgs/index/nav3.png" class="icon" alt="" />
                    <p class="fs_14 info_color">灵活用工进行中</p>
                    <div class="mt_15 color_333">
                        <span class="fs_26">{{ pendingData.zlh_num || 0 }}</span>
                        <span class="fs_14">人</span>
                    </div>
                </div>
            </div>
            <div class="right flex_1">
                <div @click="authPath(['/statistics'], linkTo)" class="chart-btn fs_14 txt btn">数据面板</div>
                <div class="companyInfo" v-if="userInfo.company_status === 'normal'">
                    <router-link to="/account" class="flex company_name">
                        <el-avatar :src="companyInfo.logo" :size="52" fit="contain" class="mr_15 logo" alt="LOGO">
                            <img :src="$store.state.baseLogo" />
                        </el-avatar>
                        <div class="flex_1 fs_12" :style="{ width: '240px' }">
                            <p class="fs_16 bold color_333 one">
                                {{ companyInfo.company_name }}
                            </p>
                            <img src="@/assets/imgs/index/auth-tip.png" class="auth-tip" alt="已认证" />
                        </div>
                    </router-link>
                    <div class="flex CRC">
                        <router-link to="/account?id=comcrc" class="flex_cen">
                            <span class="fs_14 color_333 mr_10">企业CRC</span>
                            <span class="fs_16 price_color fw_6" style="font-family:Segoe UI;">{{ companyInfo.balance }}</span>
                        </router-link>
                        <router-link to="/account?id=depcrc" class="flex_cen">
                            <span class="fs_14 color_333 mr_10">部门CRC</span>
                            <span class="fs_16 price_color fw_6" style="font-family:Segoe UI;">{{ companyInfo.credits }}</span>
                        </router-link>
                    </div>
                </div>
                <div class="noauth flex_cen" v-else>
                    <router-link to="/create" v-if="userInfo.company_status === 'no_apply'">
                        <el-button type="primary" class="fs_14">立即认证企业</el-button>
                    </router-link>
                    <router-link to="/inReview" v-else>
                        <el-button type="primary" class="fs_14">查看审核进度</el-button>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- <div @click="authPath(['/dimission'], linkTo)" class="banner-tip btn">
            <img src="@/assets/imgs/index/tip-banner.png" alt="" />
        </div> -->

        <el-image :src="require('@/assets/imgs/index/tip-banner.png')" @click="authPath(['/dimission'], linkTo)" class="banner-tip btn"></el-image>

        <div class="new-people bgf">
            <div class="title flex">
                <div class="flex_end">
                    <span class="fs_24 bold mr_10">新人推荐</span>
                    <span class="fs_14 color_999">职路签章认证，信息真实可靠！</span>
                </div>
                <router-link to="/hall" class="fs_14 color_999">查看更多人才 <span class="el-icon-arrow-right fs_12"></span></router-link>
            </div>
            <div class="list flex_c">
                <div class="item" v-for="(item, index) in recommendList" :key="index" v-show="!loading">
                    <div class="center">
                        <el-avatar :src="item.avatar" :size="120" class="avatar">
                            <img :src="item.gender=='男'?$store.state.nanAvatar:$store.state.nvAvatar" />
                        </el-avatar>
                        <div class="fs_20 bold color_333 mt_15" style="font-family:Segoe UI;">
                            {{ item.realname }}
                        </div>
                        <div class="mt_5 fs_14 color_999 one flex_cen">
                            <span class="one">{{ item.current_city }}</span>
                            <span class="sex">{{ item.gender || '-' }}</span>
                            <span>{{ item.work_exp }}</span>
                        </div>
                    </div>
                    <div class="top_link infos mt_15">
                        <div class="job" v-if="item.last_work_exp.company_name">
                            <p class="fs_14 color_666 one">
                                {{ item.last_work_exp.company_name }}
                            </p>
                            <div class="flex mt_10">
                                <div class="fs_16 bold one flex_1">
                                    {{ item.last_work_exp.job_name }}
                                </div>
                                <div class="fs_14 color_999">
                                    {{ item.last_work_exp.start_time }}至{{
										item.last_work_exp.end_time
									}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex_cen flex_1" style="height:100%" v-if="loading">
                    <p class="fs_16 center color_999 padding_15">
                        <i class="el-icon-loading fs_20"></i> 努力加载中...
                    </p>
                </div>
            </div>
        </div>

        <!-- <div class="new-people bgf" style="margin-bottom:0;" v-if="pendingData.company && pendingData.company.length > 0">
            <div class="title flex">
                <div class="flex_end">
                    <span class="fs_24 bold mr_10">共享联盟</span>
                    <span class="fs_14 color_999">大厂云集，合作共赢！</span>
                </div>
            </div>
            <div class="list share flex_c">
                <div class="item-logo" v-for="(item, index) in pendingData.company" :key="index" v-show="!loading">
                    <el-image :src="item.logo" style="width: 110px; height: 110px" :size="110" :fit="'contain'"></el-image>
                </div>
                <div class="flex_cen flex_1" style="height:100%" v-if="loading">
                    <p class="fs_16 center color_999 padding_15">
                        <i class="el-icon-loading fs_20"></i> 努力加载中...
                    </p>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
	name: "index",
	data() {
		return {
			loading: true,
			keywords: "",
			hotList: [],
			pendingData: {},
			newList: [],
			recommendList: []
		}
	},
	created() {
		this.init()
	},
	computed: {
		...mapGetters({
			userInfo: "getUserInfo",
			companyInfo: "getCompanyInfo"
		})
	},
	methods: {
		init() {
			this.$axios({
				url: "/ent/v3/index",
				method: "GET",
				hideLoading: true
			})
				.then(res => {
					if (res.code === 200) {
						this.loading = false
						this.pendingData = res.data
						this.hotList = res.data.keywords
					}
				})
				.then(() => {
					this.getRecommendList()
				})
		},
		//人才列表
		getRecommendList() {
			this.$axios({
				url: "/ent/v3/talent/recommend",
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.loading = false
					this.recommendList = res.data.list
				}
			})
		},
		handleValue(v) {
			this.keywords = v
			this.search()
		},
		keyupSearch(e) {
			if (e.keyCode === 13) {
				this.search()
			}
		},
		search() {
			if (!this.keywords) return
			this.$router.push(`/hall?keywords=${this.keywords}`)
		},
		postJob() {
			this.$router.push(`/position/post`)
		},
		linkTo(path) {
			this.$router.push(path)
		}
	}
}
</script>

<style lang="less" scoped>
.banner {
    height: 280px;
    background-color: rgba(154, 154, 154, 0.6);
    background: url("../../assets/imgs/index/banner.png") 100%/100%;
    .search-util {
        width: 780px;
        .search-bar {
            margin: 30px 0 20px;
            /deep/.el-input__inner {
                height: 50px;
                line-height: 50px;
                width: 400px;
                padding-left: 30px;
                border-radius: 4px 0 0 4px;
            }
            /deep/.el-button {
                width: 160px;
                height: 50px;
                border-radius: 0 4px 4px 0;
            }
            /deep/.post-btn {
                width: 200px;
                border-radius: 4px;
                color: #596378;
            }
        }
    }
}
.pending-bar {
    height: 170px;
    overflow: hidden;
    margin: 30px 0;
    border-radius: 2px;
    border: 1px solid #ededed;
    .left {
        width: 800px;
        .item {
            width: 33.33%;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 100px;
                background: #f8f8f8;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                z-index: 1;
            }
        }
        .icon {
            width: 52px;
            height: 52px;
            margin-bottom: 2px;
        }
    }
    .right {
        height: 170px;
        box-shadow: 0 0 6px 5px #f6f6f6;
        position: relative;
        .companyInfo {
            height: 100%;
            padding: 0 40px;
            .company_name {
                padding: 24px 10px;
            }
        }
        .noauth {
            height: 100%;
            background-image: url(../../assets/imgs/tmp_bg.png);
            background-size: 100%;
        }
        .chart-btn {
            position: absolute;
            width: 28px;
            height: 80px;
            padding: 8px;
            background: #ffb61c;
            border-radius: 7px 0 0 7px;
            line-height: 16px;
            left: -29px;
            top: 20px;
            z-index: 1;
        }
        .logo {
            width: 52px;
            height: 52px;
            border-radius: 100%;
            margin-right: 20px;
        }
        .auth-tip {
            width: 72px;
            margin-top: 5px;
        }
        .CRC {
            // padding: 20px 0;
            border-top: 1px solid #ededed;
            // margin-top: 27px;
            >.flex_cen {
                padding: 24px 10px;
            }
        }
    }
}
.new-people {
    margin: 30px 0;
    border-radius: 2px;
    .title {
        padding: 35px 55px;
        border-bottom: 1px solid #ededed;
    }
    .list {
        height: 338px;
        .item {
            width: 300px;
            height: 100%;
            // height: 384px;
            padding: 40px 40px 20px;
            box-shadow: none;
            transition: box-shadow 0.3s ease;
            &:hover {
                box-shadow: 0px 0px 12px rgba(154, 154, 154, 0.16);
                transition: box-shadow 0.3s ease;
            }
            .avatar {
                // width: 120px;
                // height: 120px;
                border-radius: 100%;
                margin: 0 auto;
            }
            .sex {
                padding: 0 8px;
                margin: 0 10px;
                border-left: 1px solid #f3f4f5;
                border-right: 1px solid #f3f4f5;
            }
            .infos {
                .job {
                    padding: 10px 0;
                }
            }
        }
        .item:not(:nth-child(5n)) {
            border-right: 1px solid #f6f6f6;
        }
    }
    .share {
        height: 190px;
        padding: 40px;
        .item-logo {
            width: 110px;
            height: 110px;
        }
        .item-logo:not(:last-child) {
            margin-right: 59px;
        }
    }
}
.color4b {
    color: #4b87ff;
    margin-left: 20px;
}
</style>
